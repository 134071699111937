<template>
  <div class="content">

    <div class="titlebarlayer">
      <div class="titlelayer">
        <div class="taglayer">
            <img src="../../../assets/img/phone/returnImg.png"  @click="Click_backPage"/>
          <div class="tag">全部</div>
        </div>
      </div>
    </div>

    <div class="contentlayer">
      <div class="itemlistlayer">
        <a>
          <div class="itemlayer">
            <div class="iteminlayer">
              <img :src="data.img" />
              <div class="contentarea">
                <div class="titlelayer">
                  <p>{{data.titlename}}</p>
                </div>
                <div class="taglayer">
                  <p class="timelayer">发布时间：{{ data.publishertime }}</p>
                  <p class="userlayer">发布人：{{ data.publisher }}</p>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="line">
      <img src="../../../assets/img/phone/line.png" />
    </div>
    <div class="showlistlayer" v-for="p in data.imgs ">
      <img
        :src="p"
        class="showlistlayer"
      />
     
    </div>
     <router-link :to="`/down/${id}`">
    <div class="downlayer">
      <div class="txt">
        <p>资料下载</p>
      </div>
    </div>
      </router-link>
  </div>
</template>

<script>

import { getDescdata } from "@/utils/api";  
import { GetDownUrl } from "@/utils/api";  
export default {
  name: "Item",
  data() {
    return {
         isdwon: "none",
      iserror:"none",
      allTexts: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串
      width: 120,
      height: 40,
      imgCode: "",
      seachtxt: "",
      codetxt :"",
      id:"",
      data:{
        // img:"http://1.jmxiazai.com/test/images_2.png",
        // titlename:"一年级数学",
        // grade:"一年级",
        // publisher:"木林森",
        // publishertime:"2024-02-07",
        // filetype:'pdf',
        // labeltag:"一年级语文  一年级口算",
        // imgs:[
        //   "http://1.jmxiazai.com/test/images_2.png",
        //   "http://1.jmxiazai.com/test/images_2.png",
        //   "http://1.jmxiazai.com/test/images_2.png",
        //   "http://1.jmxiazai.com/test/images_2.png",
        // ]
      }
    };
  }, 
   methods: {
    
    Click_backPage() {
      this.$router.back(-1);
    },
    down_url(){
      if(this.imgCode.toLowerCase() == this.codetxt.trim().toLowerCase()){
        
         this.iserror ="none"
        GetDownUrl({
          id:this.id
        }).then((res)=>{
          if(res.data.code == 200){
            this.downloadFile(res.data.downurl,res.data.filename);
          }
        })
        this.handleDraw();
      }
      else{
         this.iserror ="inline"
      }
    },
    getUrlParam(id){
        this.id=id;
       getDescdata({
        id: this.id,
      }).then((res) => {
        this.data = res.data.data;
        console.log(this.data);
      });
        
    },
     downloadImage(url) {
      var downtext = this.data.titlename;
      if(this.data.filetypeval == 2){
          downtext=downtext+".zip"
      }
      else if(this.data.filetypeval == 3){
          downtext=downtext+".pdf"
      }
      else if(this.data.filetypeval == 5){
          downtext=downtext+".doc"
      }
      this.downloadFile(url, downtext);
    },
    downloadFile(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        const blob = new Blob([xhr.response]);
        if ("download" in document.createElement("a")) {
          const link = document.createElement("a");
          link.download = fileName;
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        } else {
          navigator.msSaveBlob(blob, fileName);
        }
      };
      xhr.send();
    },
    selectkeyword() {
      if (this.seachtxt != "") {
        this.$router.push({
          path: `/searchpage/0/0/${this.seachtxt}`,
        });
      }
    },
  
    //todo 点击图片刷新
    handleDraw() {
      this.imgCode = this.draw();
    },
    //todo 产生随机数
    randomNum(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    },
    //todo 产生随机颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max);
      const g = this.randomNum(min, max);
      const b = this.randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    },
    //todo 绘图
    draw() {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      //!! 1.填充背景颜色，背景颜色要浅一点
      ctx.fillStyle = this.randomColor(180, 230);
      // 填充的位置
      ctx.fillRect(0, 0, this.width, this.height);
      let imgCode = "";
      //!! 2.随机产生字符串，并且随机旋转
      for (let i = 0; i < 4; i++) {
        // 随机的四个字
        const text = this.allTexts[this.randomNum(0, this.allTexts.length)];
        imgCode += text;
        // 随机的字体大小
        const fontSize = this.randomNum(18, 40);
        // 字体随机的旋转角度
        const deg = this.randomNum(-30, 30);
        ctx.font = fontSize + "px Arial";
        ctx.textBaseline = "top";
        ctx.fillStyle = this.randomColor(80, 150);
        ctx.save();
        ctx.translate(30 * i + 15, 15);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(text, -15 + 5, -15);
        ctx.restore();
      }
      //!! 3.随机产生5条干扰线
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.lineTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.strokeStyle = this.randomColor(180, 230);
        ctx.closePath();
        ctx.stroke();
      }
      //!! 4.随机产生40个干扰的小点
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height),
          1,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fillStyle = this.randomColor(150, 200);
        ctx.fill();
      }
      return imgCode;
    },
   
  }, mounted(){
      console.log(123);
       this.getUrlParam(this.$route.params.id);
    },
    created(){
      document.title="中继站 资料详情介绍"
    }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
html {
  height: 100vw;
  width: 100vw;
}
body {
  background-color: #90dafd;
  margin: 0;
  font-size: 0.13rem;
  display: flex;
  flex-direction: column;
  background-color: #90dafd;
}
.content {
  background-color: #90dafd;
}
#app {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  background-color: #90dafd;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.content {
  height: 100%;
  background-color: #90dafd;
  margin-bottom: 20px;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}
.titlebarlayer {
  height: 12vw;
  background-color: #90dafd;
}

.titlebarlayer .titlelayer {
  background-color: #4bcdff;
  width: 100%;
  height: 100%;
}

.titlebarlayer .titlelayer .taglayer {
  padding: 0px 20px 0px 20px;
  display: flex;
  line-height: 12vw;
  justify-content: center;
  align-content: center;
}

.titlebarlayer .titlelayer .taglayer img {
  width: 6.3vw;
  height: 6.3vw;
  margin-top: 3vw;
}
.titlebarlayer .titlelayer .taglayer a {
  display: flex;
  justify-content: center;
  align-content: center;
}

.titlebarlayer .titlelayer .taglayer .tag {
  color: #fff;
  height: 45px;
  /* line-height: 45px; */
  font-size: 4.3vw;
  flex:1;
}

.titlebarlayer .titlelayer .taglayer .img {
  color: #fff;
  padding-top: 3px;
  margin-left: 5px;
}

.titlebarlayer .titlelayer .taglayer .img img {
  width: 18px;
  height: 18px;
}

.itemlistlayer .itemlayer {
  background-color: #ffff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
}

.itemlistlayer .itemlayer .iteminlayer {
  background-color: #e0ebff;
  display: flex;
  width: 100%;
}

.itemlistlayer .itemlayer .iteminlayer img {
  height: 25vw;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea {
  flex: 1;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .titlelayer {
  color: #1e62eb;
  font-size: 4vw;
  display: flex;
  height: 18vw;
  padding: 4.5vw 0vw 0vw 3vw;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .taglayer {
  color: #24adde;
  font-size: 2.4vw;
  display: flex;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .taglayer .timelayer {
  justify-content: flex-start;
  display: flex;
  padding: 1vw;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .taglayer .userlayer {
  justify-content: flex-end;
  flex: 1;
  display: flex;
  margin-right: 10vw;
  padding: 1vw;
}
.line {
  /* height: 2px; */
}

.line img {
  width: 100%;
}
.showlistlayer {
  background-color: #ffff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  min-height: 300px;
  justify-content: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.showlistlayer img {
  width: 85%;
}
.downlayer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 10vw;
}
.downlayer .txt {
  background-color: #f67148;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 70%;
  height: 9vw;
  align-items: center;
  border-radius: 20px;
}
.downloadframe_layer {
  display: inline;
}

.downloadframe_layer .frame_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #555;
  opacity: 0.7;
  min-width: 1240px;
}

.downloadframe_layer .downloadbox_layer {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
}

.downloadframe_layer .downloadbox_layer img {
  margin-top: 230px;
  position: absolute;
}

.downloadframe_layer .txt_layer {
  position: absolute;
  width: 537px;
  height: 304px;
  margin-top: 350px;
  display: flex;
}

.downloadframe_layer .txt_layer input {
  height: 40px;
  width: 180px;
  margin-left: 100px;
  font-size: 22px;
  z-index:101
  /* margin-top: 5px; */
}

.downloadframe_layer .txt_layer canvas {
  width: 120px;
  height: 40px;
  /* background-color: #49cbff; */
  margin-left: 20px;
}

.downloadframe_layer .btn_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 200px;
}

.downloadframe_layer .btn_layer img {
  width: 320px;
}
</style>