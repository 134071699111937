import axios from './request'

export const GetIndexDataMapping =(data)=>{

    return axios.postMapping("/learn/getIndex",data)
}
export const GetIndexData =(data)=>{    
    // console.log("GetIndexData"+data);
    return axios.post("/learn/getIndex",data)
}

export const GetMainPageData=(data)=>{
    return axios.post("/learn/file/getfilemain")
}
export const GetTypeListData=(data)=>{
    return axios.post("/learn/file/getTypeList")
}
export const GetDescListData=(data)=>{
    // console.log("GetDescListData"+data);
    return axios.post("/learn/file/getfilerlist",data)
}
export const GetDescListLabelData=(data)=>{
    // console.log("GetDescListData"+data);
    return axios.post("/learn/file/getfilerlistByLabel",data)
}
export const getDescdata=(data)=>{
    // console.log("GetDescListData"+data);
    return axios.post("/learn/file/getfilebyid",data)
}
export const updata=(data)=>{
    console.log("updata");
    // console.log("GetDescListData"+data);
    return axios.postFile("/learn/file/updata",data)
}

export const GetSearchList=(data)=>{
    return axios.post("/learn/file/searchIndex")
}
export const GetAllDataList=(data)=>{
    return axios.post("/learn/file/getfiledlist",data)

}
export const GetDownUrl=(data)=>{
    return axios.post("/learn/file/getDownUrl",data)

}
export const updateMess=(data)=>{
    return axios.post("/learn/file/UpdateMess",data)

}