<template>
  <div class="box_layer wlimit">
    <div class="box">
      <router-link
        class="box_item"
        v-for="(p, index) in items"
        :key="index"
        :to="`/DetailPage/${p.id}`"
        target="_blank"
      >
        <img :src="p.url" style="height: 403px" />
        <div>
          <p>{{ p.tag }}</p>
        </div>
      </router-link>
    </div>
    <div v-if="loading">加载中...</div>
  </div>
</template>

<script>
import { GetAllDataList } from "@/utils/api";

export default {
  name: "ItemList",
  data() {
    return {
      page: 0,
      loading: false,
      isfinish: true,
      getDataType: 0, //获取数据类型  0：全部数据 1：年级+模糊 2 关键字搜索
      pageSize: 20,
      grade_selectIndex: 0,
      seacher_selectIndex: 0,
      seachtxt: "",
      items: [],
      seacheritem: [
        { tag: "全部", id: 0 },
        { tag: "语文", id: 1 },
        { tag: "数学", id: 2 },
        { tag: "外语", id: 3 },
        { tag: "阅读", id: 4 },
        { tag: "知识", id: 5 },
        { tag: "课本", id: 6 },
        { tag: "习题", id: 7 },
        { tag: "口算", id: 8 },
        { tag: "晨读", id: 9 },
        { tag: "拼音", id: 10 },
        { tag: "字帖", id: 11 },
      ],
    };
  },
  methods: {
    getAllList(grade, labelname, searchkey) {
      if (labelname != 0) {
        for (var i = 0; i < this.seacheritem.length; i++) {
          if (labelname == this.seacheritem[i].id) {
            labelname = this.seacheritem[i].tag;
            break;
          }
        }
      }
      var theoffset = this.page * this.pageSize;
      GetAllDataList({
        grade: grade,
        labelname: labelname,
        searchkey: searchkey,
        offset: theoffset,
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.items = [...this.items, ...res.data.data];
        } else {
          this.isfinish = false;
          console.log("没有数据了");
        }
      });
      this.page++;
    },
    getUrlParam(gradeid, selectid, keyword) {
      window.scrollTo(0, 0);
      console.log("123", gradeid, selectid);
      if (
        gradeid == undefined ||
        (gradeid == 0 && selectid == 0 && keyword == 0)
      ) {
        console.log(gradeid,selectid,keyword)
        //首页数据加载
        this.grade_selectIndex = 0;
        this.seacher_selectIndex = 0;
        this.seachtxt = "";
        this.getDataType = 0;
        this.getAllList(this.grade_selectIndex, this.seacher_selectIndex, 0);
        console.log("123", gradeid, selectid, this.getDataType);
      } else {
        //根据班级与索引值加载数据
        if (keyword != 0) {
          console.log("模糊查询");
          this.getDataType = 2;
          this.seachtxt = keyword;
          this.getAllList(gradeid, 0, this.seachtxt);
        } else {
          console.log("非模糊查询");
          this.grade_selectIndex = gradeid;
          this.seacher_selectIndex = selectid;
          this.seachtxt = "";
          this.getDataType = 1;
          this.getAllList(this.grade_selectIndex, this.seacher_selectIndex, 0);
        }
      }
    },
    handleScroll(e) {
      var scrollHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight
      ); //文档高度
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条滚动距离
      var clientHeight =
        window.innerHeight ||
        Math.min(
          document.documentElement.clientHeight,
          document.body.clientHeight
        ); //窗口可视化范围高度
      // console.log(clientHeight + scrollTop,scrollHeight, this.loading,this.isfinish);
      if (
        clientHeight + scrollTop + 2 >= scrollHeight &&
        this.loading == false &&
        this.isfinish == true
      ) {
        this.loading = true;
        this.loadMore();
        // console.log("===到底了===");
      }
    },
    loadMore() {
      setTimeout(() => {
        // 模拟获取数据
        console.log("翻页.....", this.getDataType);
        if (this.getDataType == 0) {
          this.getAllList(0, 0, 0);
        } else if (this.getDataType == 1) {
          this.getAllList(this.grade_selectIndex, this.seacher_selectIndex, 0);
        } else if (this.getDataType == 2) {
          this.getAllList(0, 0, this.seachtxt);
        }
        this.loading = false;
      }, 2000);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getUrlParam(
      this.$route.params.graderid,
      this.$route.params.searchid,
      this.$route.params.keyword
    );
  },
};
</script>

<style scoped>
.box_layer {
  margin-top: 10px;
}

.box {
  overflow: hidden;
  margin-right: -33px;
  padding-top: 4px;
}

.box .box_item {
  position: relative;
  float: left;
  overflow: hidden;
  margin-right: 4pc;
  margin-bottom: 26px;
  width: 3in;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* behavior: url(ie-css3.htc) */
}

.box .box_item img {
  width: 100%;
}

.box .box_item p {
  display: inline-block;
  overflow: hidden;
  /* width: 225px; */
  height: 44px;
  color: #252525;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  /* line-height: 24px; */
}

.box .box_item .explain {
  display: block;
  padding: 0 30px;
  background: #fff;
}

.box .box_item .explain .coll {
  display: block;
  width: 100%;
  height: 44px;
  border-top: 1px solid #eceff1;
  color: #838383;
  font-size: 14px;
  font-family: Arial;
  line-height: 44px;
}

.box .box_item .explain .shoucang_num {
  position: relative;
  float: left;
  width: 45%;
  text-align: left;
}

.box .box_item .explain .shoucang_num i {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../../../assets/img/pc/down.png) -9px -1394px no-repeat;
  vertical-align: -5px;
}
</style>