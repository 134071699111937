<template>
  <div id="ccc">
    <MainPage />
    <!-- <router-view :key="$route.fullPath"></router-view> -->
    <HomePage />
  </div>
</template>

<script>
import MainPage from "./MainPage.vue";
import HomePage from "./HomePage.vue";
// import SearchPage from "./SearchPage.vue";
// import MainLead from "./MainLead.vue"

export default {
  name: "MainIndex",
  data() {
    return {};
  },
  components: {
    MainPage,
    HomePage,
    // SearchPage,
    // MainLead
  },
};
</script>

<style scoped>
body {
  min-width: 1200px;
  background: #fff;
  color: #000;
  text-align: center;
  font-family: 微软雅黑;
  margin: 0px;
}
#ccc {
  background-color: #eff2f9;
  /* margin-top: 60px; */
}

div {
  display: block;
}

.wlimit {
  margin: 0 auto;
  width: 90pc;
}

.line {
  height: 2px;
  background-color: #eeeeee;
  width: 100%;
}

a {
  cursor: pointer;
}
</style>