<template>
  <div class="content">
    <div class="titlebarlayer">
      <div class="titlebarmainlayer">
        <router-link :to="`/searchindex`" class="searchbtn_a_layer">
          <div class="txt">全部</div>
          <div class="img">
            <img src="../../assets/img/phone/arrowright.png" />
          </div>
        </router-link>
        <div class="searchinput_layer">
          <div class="searchbackground">
            <img src="../../assets/img/phone/search.png" />
            <input type="text" placeholder="关键字搜索"  v-model="seachtxt"  />
            <div class="btn"  @click="selectkeyword">搜索</div>
          </div>
        </div>
      </div>
      <div class="showimg">
        <img src="../../assets/img/phone/rollimg.png" />
      </div>
      <div class="contentlayer">
        <div class="allsearchlayer">
          <div class="labelsearch">
            <div class="navigationlayer">
              <div class="onelayer">
                <router-link :to="`/searchpage/${1}/${0}/0`">
                  <div class="typelayer">
                    <p>幼小衔接</p>
                  </div>
                </router-link>
                <router-link :to="`/searchpage/${2}/${0}/0`">
                  <div class="typelayer">
                    <p>一年级</p>
                  </div>
                </router-link>
              </div>
              <div  class="twolayer">
                <router-link :to="`/searchpage/${3}/${0}/0`">
                  <div class="typelayer">
                    <p>二年级</p>
                  </div>
                </router-link>
                <router-link :to="`/searchpage/${4}/${0}/0`">
                  <div class="typelayer">
                    <p>三年级</p>
                  </div>
                </router-link>
                <router-link :to="`/searchpage/${5}/${0}/0`">
                  <div class="typelayer">
                    <p>四年级</p>
                  </div>
                </router-link>
              </div>
              <div class="twolayer">
                <router-link :to="`/searchpage/${6}/${0}/0`">
                  <div class="typelayer">
                    <p>五年级</p>
                  </div>
                </router-link>
                <router-link :to="`/searchpage/${7}/${0}/0`">
                  <div class="typelayer">
                    <p>六年级</p>
                  </div>
                </router-link>
                <router-link  :to="`/searchindex`">
                  <div class="typelayer">
                    <p>更多>></p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="oneclasslayer">
          <div class="titlelayer">
            <p>学科资料分享</p>
          </div>
          <div class="line">
            <img src="../../assets/img/phone/line.png" />
          </div>
          <div class="selectlayer">
            <div class="class_layer">
              <router-link :to="`/searchpage/${0}/${0}/语文`">
                <div class="showlayer yu">
                  <p class="title">语 文</p>
                  <p class="tag">试卷及复习资料</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/数学`">
                  <div class="showlayer shu">
                  <p class="title">数 学</p>
                  <p class="tag">口算及小卷资料</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/外语`">
                 <div class="showlayer wai">
                  <p class="title">外 语</p>
                  <p class="tag">课外读物与小卷</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/试卷`">
                  <div class="showlayer shi">
                  <p class="title">试 卷</p>
                  <p class="tag">全国各地试卷</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="twoclass">
          <div class="titlelayer">
            <p>更多资料分享</p>
          </div>
          <div class="line">
            <img src="../../assets/img/phone/line.png" />
          </div>
          <div class="selectlayer">
            <div class="two_class_layer">
              <router-link :to="`/searchpage/${0}/${0}/阅读`">
                <img src="../../assets/img/phone/1.png" />
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/外语`">
                <img src="../../assets/img/phone/2.png" />
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/数学`">
                <img src="../../assets/img/phone/3.png" />
              </router-link>
            </div>
            
            <div class="two_class_layer">
              <router-link :to="`/searchpage/${0}/${0}/语文`">
                <img src="../../assets/img/phone/4.png" />
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/试卷`">
                <img src="../../assets/img/phone/6.png" />
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/口算`">
                <img src="../../assets/img/phone/7.png" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {};
  },
  mounted() {
    console.log(1);
    document.body.style.backgroundColor = "#90dafd";
  },
  methods:{
      selectkeyword(){
      if (this.seachtxt != "") {
        this.$router.push({
          path: `/searchpage/${0}/0/${this.seachtxt}`,
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
html {
  height: 100vw;
  width: 100vw;
}
body {
  background-color: #90dafd;
  margin: 0;
  font-size: 0.13rem;
  display: flex;
  flex-direction: column;
  background-color: #90dafd;
  // overflow: hidden;
}
.content {
  background-color: #90dafd;
}
#app {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  background-color: #90dafd;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.content {
  height: 100%;
  background-color: #90dafd;
  margin-bottom: 20px;
}

.line {
  height: 2px;
  margin-bottom:7vw;
}

.line img {
  width: 100%;
}
.titlebarlayer {
  background-color: #4bcdff;
  width: 100%;
  // height: 12vw;
}

.titlebarlayer .titlebarmainlayer {
  padding: 0px 20px 0px 20px;
  display: flex;
  height: 12vw;
}

.titlebarlayer .titlebarmainlayer .searchbtn_a_layer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titlebarlayer .titlebarmainlayer .searchbtn_a_layer .txt {
  color: #fff;
  height: 45px;
  line-height: 45px;
  font-size: 3.3vw;
}

.titlebarlayer .titlebarmainlayer .searchbtn_a_layer .img {
  color: #fff;
  margin-left: 5px;
}

.titlebarlayer .titlebarmainlayer .searchbtn_a_layer .img img {
  width: 18px;
  height: 18px;
}

.titlebarlayer .titlebarmainlayer .searchinput_layer {
  // line-height: 45px;
  flex: 1;
  padding: 5px 30px 10px 10px;
  display: flex;
  width: 14vw;
  justify-content: center;
  margin-top: 10px;
}

.titlebarlayer .titlebarmainlayer .searchinput_layer .searchbackground {
  border: none;
  background-color: #fff;
  border-radius: 4.33vw;
  height: 8vw;
  display: flex;
  width: 100%;
}

.titlebarlayer .titlebarmainlayer .searchinput_layer .searchbackground img {
  width: 6.5vw;
  height: 6.5vw;
  margin: 5px;
}

.titlebarlayer .titlebarmainlayer .searchinput_layer .searchbackground input {
  width: 60%;
  border: none;
  background-color: #fff;
  // border-rus: 20px;
  height: 100%;
  margin-left: 5px;
  flex: 1;
  font-size: 3.3vw;
  color: #1d1d1d;
}

.titlebarlayer .titlebarmainlayer .searchinput_layer .searchbackground .btn {
  width: 13vw;
  height: 6.33vw;
  border-radius: 3.33vw;
  background-color: #f67148;
  color: #fff;
  font-size: 3.86vw;
  line-height: 6.33vw;
  text-align: center;
  justify-items: center;
  margin: 5px;
}
.titlebarlayer .showimg img {
  width: 100%;
}
.contentlayer {
  width: 100%;
  padding-bottom: 30px;
}

.typelayer {
  width: 16.33vw;
  height: 9vw;
  background-color: #d6e4fe;
  border-radius: 20px;
  color: #1e62eb;
  font-size: 3.3vw;
  line-height: 9vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px 0px 0px 10px;
}
.allsearchlayer {
  background-color: #ffff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
}
.allsearchlayer .labelsearch {
  background-color: #e0ebff;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-flow: row;
}

.allsearchlayer .labelsearch .navigationlayer {
  display: flex;
  flex-wrap: wrap;
  // padding: 5px 0px 5px 5px;
  width: 100%;
  margin-bottom: 7vw;
}
.allsearchlayer .labelsearch .navigationlayer .onelayer{
  display: flex;
  flex-wrap: wrap;
  // padding: 5px 0px 5px 5px;
  width: 100%;
  justify-content: center;
  height: 12vw;
}
.allsearchlayer .labelsearch .navigationlayer .onelayer a{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 5px 5px;
  width: 48%;
}
.allsearchlayer .labelsearch .navigationlayer .onelayer .typelayer{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 5px 5px;
  width: 100%;
}
.allsearchlayer .labelsearch .navigationlayer .twolayer{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 5px 5px;
  width: 100%;
  justify-content: center;
  height: 12vw;
}
.allsearchlayer .labelsearch .navigationlayer .twolayer a{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 5px 5px;
  width: 32%;
}
.allsearchlayer .labelsearch .navigationlayer .twolayer .typelayer{
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 5px 5px;
  width: 100%;
}
.allsearchlayer .labelsearch .navigationlayer .typelayer {
  width: 16.33vw;
  height: 9vw;
  background-color: #d6e4fe;
  border-radius: 20px;
  color: #1e62eb;
  font-size: 3.3vw;
  line-height: 9vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 3.33vw 0px 3.33vw 1.33vw;
  z-index: 10;
}

.allsearchlayer .labelsearch .backgroundimg {
  // width: 100px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.allsearchlayer .labelsearch .backgroundimg img {
  width: 26.33vw;
  height: 26.33vw;
  margin-left: -15vw;
}
.oneclasslayer{
  margin-top: 3vw;
}
.oneclasslayer .titlelayer {
  color: #fff;
  padding-left: 30px;
  display: flex;
}
.twoclass .titlelayer {
  color: #fff;
  padding-left: 30px;
  display: flex;
}
.threeclass .titlelayer {
  color: #fff;
  padding-left: 30px;
  display: flex;
}
.oneclasslayer .selectlayer {
  background-color: #fff;
  border-radius: 20px;
  margin: 20px;
  display: flex;
  margin-top: 30px;

}

.oneclasslayer .selectlayer .class_layer {
  // width: 70%;
  padding: 2vw 0px 2vw 1.33vw;
  display: flex;
  flex-wrap: wrap;
}

.oneclasslayer .selectlayer .class_layer a {
  margin: 10px 5px 0px 10px;
  // width: 27.66vw;
  height: 14.6vw;
  display: block;
}
.oneclasslayer .selectlayer .class_layer .showlayer {
  // margin: 10px 5px 0px 10px;
  width: 21vw;
  height: 11vw;
  display: block;
  border-radius: 4vw;
}
.oneclasslayer .selectlayer .class_layer .showlayer {
  // margin: 10px 5px 0px 10px;
  width: 21vw;
  height: 11vw;
  display: block;
  border-radius: 2vw;
  display: flex;
  flex-flow: column;
  padding: 1vw;
}
.oneclasslayer .selectlayer .class_layer .showlayer  .title{
  color:#fff;
  font-size: 3.5vw;
  // margin-left: -10vw;
}
.oneclasslayer .selectlayer .class_layer .showlayer  .tag{
  color:#fff;
  font-size: 2.5vw;
}
.oneclasslayer .selectlayer .class_layer .yu {
  background-color: #609BFF;
}
.oneclasslayer .selectlayer .class_layer .shu {
  background-color: #C86CEF;
}
.oneclasslayer .selectlayer .class_layer .wai {
  background-color: #9FBE6C;
}
.oneclasslayer .selectlayer .class_layer .shi {
  background-color: #FD7686;
}
.oneclasslayer .selectlayer .class_layer img {
  width: 26vw;
}

.oneclasslayer .selectlayer .backgroudimg {
  display: flex;
  align-items: center;
  width: 30%;
}

.oneclasslayer .selectlayer .backgroudimg img {
  width: 23.667vw;
  height: 36.067vw;
}
.oneclasslayer,
.twoclass,
.threeclass .titlelayer {
  color: #fff;
  // padding-left: 30px;
  font-size: 4vw;
}
.twoclass .selectlayer {
  background-color: #fff;
  border-radius: 20px;
  margin: 20px;
  display: flex;
  margin-top: 30px;
  flex-flow: column;
}
.twoclass .selectlayer .two_class_layer {
  padding: 10px 0px 10px 10px;
  display: flex;
  flex-wrap: wrap;
}
.twoclass .selectlayer .two_class_layer a {
  margin: 10px 5px 0px 10px;
  width: 29%;
  display: block;
}

.twoclass .selectlayer .two_class_layer img {
  width: 100%;
}
.threeclass .titlelayer {
  color: #fff;
  // padding-left: 30px;
}

.threeclass .class_layer {
  background-color: #fff;
  border-radius: 20px;
  margin: 20px;
  display: flex;
  margin-top: 30px;
}

.three_class_layer {
  padding: 5px 0px 10px 10px;
  display: flex;
  flex-wrap: wrap;
}
.threeclass .three_class_layer a {
  margin: 10px 5px 0px 10px;
  width: 45%;
  /* height: 110px; */
  display: block;
}

.threeclass .three_class_layer img {
  width: 100%;
}
</style>