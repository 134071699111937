<template>
  <div class="content">
    <div class="titlebarlayer">
      <div class="titlelayer">
        <div class="taglayer">
          <img
            src="../../../assets/img/phone/returnImg.png"
            @click="Click_backPage"
          />
          <router-link :to="`/searchindex`" class="searchbtn_a_layer">
            <div class="tag">全部</div>
            <div class="img">
              <img src="../../../assets/img/phone/arrowright.png" />
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="contentlayer">
      <div class="searchlayer">
        <div class="showlayer">
          <img src="../../../assets/img/phone/search.png" />
          <input type="text" placeholder="关键字搜索" v-model="seachtxt" />
          <div class="txt" @click="selectkeyword">搜索</div>
        </div>
      </div>

      <div class="navigationlayer">
        <div class="navigationlistlayer">
          <div class="navigationlist">
            <router-link
              v-for="(p, index) in seacheritem"
              :to="`/searchpage/${grade_selectIndex}/${seacher_selectIndex}/0`"
            >
              <div class="typelayer" @click="selectseacher(p.id, p.tag)">
                <p>{{ p.tag }}</p>
              </div>
            </router-link>
          </div>

          <div class="img">
            <img src="../../../assets/img/phone/typeimg.png" />
          </div>
        </div>
      </div>
      <div class="itemlistlayer">
        <div v-for="(p, index) in items" :key="index">
          <div class="line">
            <img src="../../../assets/img/phone/line.png" />
          </div>
          <router-link :to="`/DetailPage/${p.id}`">
            <div class="itemlayer">
              <div class="iteminlayer">
                <img :src="p.url" />
                <div class="contentarea">
                  <div class="titlelayer">
                    <p>{{ p.tag }}</p>
                  </div>
                  <div class="taglayer">
                    <p class="timelayer">发布时间：{{ p.pubdatetime }}</p>
                    <p class="userlayer">发布人：{{ p.pubusername }}</p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="loading">加载中...</div>
  </div>
</template>

<script>
import { GetAllDataList } from "@/utils/api";

export default {
  name: "List",
  data() {
    return {
      page: 0,
      loading: false,
      isfinish: true,
      getDataType: 0, //获取数据类型  0：全部数据 1：年级+模糊 2 关键字搜索
      pageSize: 20,
      grade_selectIndex: 0,
      seacher_selectIndex: 0,
      seachtxt: "",
      items: [],
      seacheritem: [
        { tag: "全部", id: 0 },
        { tag: "语文", id: 1 },
        { tag: "数学", id: 2 },
        { tag: "外语", id: 3 },
        { tag: "阅读", id: 4 },
        { tag: "知识", id: 5 },
        { tag: "课本", id: 6 },
        { tag: "习题", id: 7 },
        { tag: "口算", id: 8 },
        { tag: "晨读", id: 9 },
        { tag: "拼音", id: 10 },
        { tag: "字帖", id: 11 },
      ],
    };
  },

  methods: {
    selectkeyword() {
      if (this.seachtxt != "") {
        this.$router.push({
          path: `/searchpage/${0}/0/${this.seachtxt}`,
        });
      }
    },
    selectseacher(index, tag) {
      this.seacher_selectIndex = index;
      this.seacher_selecttag = tag;
    },
    getAllList(grade, labelname, searchkey) {
      if (labelname != 0) {
        for (var i = 0; i < this.seacheritem.length; i++) {
          if (labelname == this.seacheritem[i].id) {
            labelname = this.seacheritem[i].tag;
            break;
          }
        }
      }
      var theoffset = this.page * this.pageSize;
      GetAllDataList({
        grade: grade,
        labelname: labelname,
        searchkey: searchkey,
        offset: theoffset,
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.items = [...this.items, ...res.data.data];
          console.log(this.items);
        } else {
          this.isfinish = false;
          console.log("没有数据了");
        }
      });
      this.page++;
    },
    getUrlParam(gradeid, selectid, keyword) {
      window.scrollTo(0, 0);
      console.log("123", gradeid, selectid);
      if (
        gradeid == undefined ||
        (gradeid == 0 && selectid == 0 && keyword == 0)
      ) {
        console.log(gradeid, selectid, keyword);
        //首页数据加载
        this.grade_selectIndex = 0;
        this.seacher_selectIndex = 0;
        this.seachtxt = "";
        this.getDataType = 0;
        this.getAllList(this.grade_selectIndex, this.seacher_selectIndex, 0);
        console.log("123", gradeid, selectid, this.getDataType);
      } else {
        //根据班级与索引值加载数据
        if (keyword != 0) {
          console.log("模糊查询");
          this.getDataType = 2;
          this.seachtxt = keyword;
          this.getAllList(gradeid, 0, this.seachtxt);
        } else {
          console.log("非模糊查询");
          this.grade_selectIndex = gradeid;
          this.seacher_selectIndex = selectid;
          this.seachtxt = "";
          this.getDataType = 1;
          this.getAllList(this.grade_selectIndex, this.seacher_selectIndex, 0);
        }
      }
    },
    handleScroll(e) {
      var scrollHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight
      ); //文档高度
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条滚动距离
      var clientHeight =
        window.innerHeight ||
        Math.min(
          document.documentElement.clientHeight,
          document.body.clientHeight
        ); //窗口可视化范围高度
      // console.log(clientHeight + scrollTop,scrollHeight, this.loading,this.isfinish);
      if (
        clientHeight + scrollTop + 2 >= scrollHeight &&
        this.loading == false &&
        this.isfinish == true
      ) {
        this.loading = true;
        this.loadMore();
        // console.log("===到底了===");
      }
    },
    loadMore() {
      setTimeout(() => {
        // 模拟获取数据
        console.log("翻页.....", this.getDataType);
        if (this.getDataType == 0) {
          this.getAllList(0, 0, 0);
        } else if (this.getDataType == 1) {
          this.getAllList(this.grade_selectIndex, this.seacher_selectIndex, 0);
        } else if (this.getDataType == 2) {
          this.getAllList(0, 0, this.seachtxt);
        }
        this.loading = false;
      }, 2000);
    },
    Click_backPage() {
      this.$router.back(-1);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getUrlParam(
      this.$route.params.graderid,
      this.$route.params.searchid,
      this.$route.params.keyword
    );
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
html {
  height: 100vw;
  width: 100vw;
}
body {
  background-color: #90dafd;
  margin: 0;
  font-size: 0.13rem;
  display: flex;
  flex-direction: column;
  background-color: #90dafd;
}
.content {
  background-color: #90dafd;
}
#app {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  background-color: #90dafd;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.content {
  height: 100%;
  background-color: #90dafd;
  margin-bottom: 20px;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}
.titlebarlayer {
  height: 12vw;
  background-color: #90dafd;
}

.titlebarlayer .titlelayer {
  background-color: #4bcdff;
  width: 100%;
  height: 100%;
}

.titlebarlayer .titlelayer .taglayer {
  padding: 0px 20px 0px 20px;
  display: flex;
  line-height: 12vw;
  justify-content: center;
  align-content: center;
}

.titlebarlayer .titlelayer .taglayer img {
  width: 6.3vw;
  height: 6.3vw;
  margin-top: 3vw;
}
.titlebarlayer .titlelayer .taglayer a {
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 1;
}

.titlebarlayer .titlelayer .taglayer .tag {
  color: #fff;
  height: 45px;
  /* line-height: 45px; */
  font-size: 4.3vw;
}

.titlebarlayer .titlelayer .taglayer .img {
  color: #fff;
  padding-top: 3px;
  margin-left: 5px;
}

.titlebarlayer .titlelayer .taglayer .img img {
  width: 18px;
  height: 18px;
}
.contentlayer {
  display: flex;
  flex-wrap: wrap;
}

.contentlayer .searchlayer {
  line-height: 45px;
  flex: 1;
  padding: 5px 30px 10px 10px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 8vw;
}

.contentlayer .searchlayer .showlayer {
  border: none;
  background-color: #fff;
  border-radius: 4.33vw;
  height: 8vw;
  display: flex;
  width: 80%;
}

.contentlayer .searchlayer .showlayer img {
  width: 6.5vw;
  height: 6.5vw;
  margin: 0.667vw;
}

.contentlayer .searchlayer .showlayer input {
  width: 60%;
  border: none;
  background-color: #fff;
  height: 100%;
  margin-left: 5px;
  flex: 1;
  font-size: 3.3vw;
  color: #1d1d1d;
}

.contentlayer .searchlayer .showlayer .txt {
  width: 13vw;
  height: 6.33vw;
  border-radius: 3.33vw;
  background-color: #f67148;
  color: #fff;
  font-size: 3.86vw;
  line-height: 6.33vw;
  text-align: center;
  justify-items: center;
  margin: 5px;
}
.navigationlayer {
  background-color: #ffff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
}

.navigationlayer .navigationlistlayer {
  background-color: #e0ebff;
  display: flex;
}

.navigationlayer .navigationlistlayer .navigationlist {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 5px 5px;
}

.navigationlayer .navigationlistlayer .navigationlist .typelayer {
  width: 16.33vw;
  height: 9vw;
  background-color: #d6e4fe;
  border-radius: 20px;
  color: #1e62eb;
  font-size: 3.3vw;
  line-height: 9vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 3.33vw 0px 3.33vw 1.33vw;
  z-index: 10;
}

.navigationlayer .navigationlistlayer .img {
  width: 100px;
  justify-content: center;
  text-align: center;
}

.navigationlayer .navigationlistlayer .img img {
  width: 31.33vw;
  margin-left: -15vw;
}

.line {
  /* height: 2px; */
}

.line img {
  width: 100%;
}

.itemlistlayer .itemlayer {
  background-color: #ffff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
}

.itemlistlayer .itemlayer .iteminlayer {
  background-color: #e0ebff;
  display: flex;
  width: 100%;
}

.itemlistlayer .itemlayer .iteminlayer img {
  height: 25vw;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea {
  flex: 1;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .titlelayer {
  color: #1e62eb;
  font-size: 4vw;
  display: flex;
  height: 18vw;
  padding: 4.5vw 0vw 0vw 3vw;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .taglayer {
  color: #24adde;
  font-size: 2.4vw;
  display: flex;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .taglayer .timelayer {
  justify-content: flex-start;
  display: flex;
  padding: 1vw;
}

.itemlistlayer .itemlayer .iteminlayer .contentarea .taglayer .userlayer {
  justify-content: flex-end;
  flex: 1;
  display: flex;
  margin-right: 10vw;
  padding: 1vw;
}
</style>