import Vue from "vue";
import VueRouter from "vue-router";

import PCIndex from '@/components/pc/Index'

import PoneIndex from '@/components/phone/Index'



// import HomePage from '@/components/pc/module/HomePage'
import SearchPage from '@/components/pc/module/SearchPage'
import DetailPage from '@/components/pc/DetailPage'
import MainIndex from '@/components/pc/module/MainIndex'
import PhoneSearch from '@/components/phone/module/List'
import PhoneDetailPage from '@/components/phone/module/Item'
import PhoneSearchPage from '@/components/phone/module/Search'
import PhoneDown from '@/components/phone/module/Down'

Vue.use(VueRouter)


const routerPc = [{
    path: '',
    name: 'Index',
    component: PCIndex,
    children: [
        {
            path: '',
            component: MainIndex
        },
        {
            path: '/searchpage/:graderid/:searchid/:keyword',
            component: SearchPage
        },

    ]
}
, {
    path: '/DetailPage/:id',
    component: DetailPage
}
]
const routerPone = [{
    path: '',
    name: 'Index',
    component: PoneIndex,
    children:[
      
    ]
},
{
    path: '/searchpage/:graderid/:searchid/:keyword',
    component: PhoneSearch
},
{
    path: '/DetailPage/:id',
    component: PhoneDetailPage
},
{
    path: '/searchindex',
    component: PhoneSearchPage
},
{
    path: '/down/:id',
    component: PhoneDown
}
]

var routes = [];
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    routes = routerPone
} else {
    routes = routerPc
}
const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

export default router