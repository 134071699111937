<template>
  <div class="content">
    <div class="titlebarlayer">
      <div class="titlelayer">
        <div class="taglayer">
          <img
            src="../../../assets/img/phone/returnImg.png"
            @click="Click_backPage"
          />
          <div class="tag">下载</div>
        </div>
      </div>
    </div>
    <div class="backImg">
      <img src="../../../assets/img/phone/down_back.png" />
    </div>
    <div class="vcodeLayer">
      <div class="contentlayer">
        <div class="title">数字认证</div>
        <input type="text" v-model="codetxt" />
        <div style="line"></div>
        <div class="canvaslayer">
          <canvas
            id="canvas"
            :width="width"
            :height="height"
            @click="handleDraw"
          ></canvas>
        </div>
      </div>
    </div>
    <div class="downbtnlayer" @click="down_url">
      <div class="btn">立即下载</div>
    </div>
  </div>
</template>

<script>
import { GetDownUrl } from "@/utils/api";
import { MessageBox } from "element-ui";

export default {
  name: "Down",
  data() {
    return {
      width: 120,
      height: 40,
      allTexts: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串
      iserror: "none",
      imgCode: "",
      seachtxt: "",
      codetxt: "",
      id: "",
    };
  },
  methods: {
    down_url() {
      if (this.imgCode.toLowerCase() == this.codetxt.trim().toLowerCase()) {
        this.iserror = "none";
        GetDownUrl({
          id: this.id,
        }).then((res) => {
          if (res.data.code == 200) {
            this.downloadFile(res.data.downurl, res.data.filename);
          }
        });
        this.handleDraw();
      } else {
        this.iserror = "inline";
        console.log("验证码错误");
      }
    },
    downloadFile(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        const blob = new Blob([xhr.response]);
        if ("download" in document.createElement("a")) {
          const link = document.createElement("a");
          link.download = fileName;
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        } else {
          console.log(typeof navigator.msSaveBlob);
          if (typeof navigator.msSaveBlob === "function") {
            // navigator.msSaveBlob(blob, fileName);
          } else {
            const file = new File([blob], fileName);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(link.href);
          }
        }
      };
      xhr.send();
    },
    Click_backPage() {
      this.$router.back(-1);
    },

    //todo 点击图片刷新
    handleDraw() {
      this.imgCode = this.draw();
    },
    //todo 产生随机数
    randomNum(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    },
    //todo 产生随机颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max);
      const g = this.randomNum(min, max);
      const b = this.randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    },
    //todo 绘图
    draw() {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      //!! 1.填充背景颜色，背景颜色要浅一点
      ctx.fillStyle = this.randomColor(180, 230);
      // 填充的位置
      ctx.fillRect(0, 0, this.width, this.height);
      let imgCode = "";
      //!! 2.随机产生字符串，并且随机旋转
      for (let i = 0; i < 4; i++) {
        // 随机的四个字
        const text = this.allTexts[this.randomNum(0, this.allTexts.length)];
        imgCode += text;
        // 随机的字体大小
        const fontSize = this.randomNum(18, 40);
        // 字体随机的旋转角度
        const deg = this.randomNum(-30, 30);
        ctx.font = fontSize + "px Arial";
        ctx.textBaseline = "top";
        ctx.fillStyle = this.randomColor(80, 150);
        ctx.save();
        ctx.translate(30 * i + 15, 15);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(text, -15 + 5, -15);
        ctx.restore();
      }
      //!! 3.随机产生5条干扰线
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.lineTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.strokeStyle = this.randomColor(180, 230);
        ctx.closePath();
        ctx.stroke();
      }
      //!! 4.随机产生40个干扰的小点
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height),
          1,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fillStyle = this.randomColor(150, 200);
        ctx.fill();
      }
      return imgCode;
    },
  },
  mounted() {
    this.handleDraw();
    this.id = this.$route.params.id;
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
html {
  height: 100vw;
  width: 100vw;
}
body {
  background-color: #90dafd;
  margin: 0;
  font-size: 0.13rem;
  display: flex;
  flex-direction: column;
  background-color: #90dafd;
}
.content {
  background-color: #fff;
}
#app {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  background-color: #90dafd;
}

input {
  border: none;
  outline: none;
  padding: 4vw;
  font-size: 4vw;
}

p {
  margin: 0px;
}

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.content {
  height: 100%;
  /* background-color: #90dafd; */
  margin-bottom: 20px;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}
.titlebarlayer {
  height: 12vw;
  background-color: #90dafd;
}

.titlebarlayer .titlelayer {
  background-color: #4bcdff;
  width: 100%;
  height: 100%;
}

.titlebarlayer .titlelayer .taglayer {
  padding: 0px 20px 0px 20px;
  display: flex;
  line-height: 12vw;
  justify-content: center;
  align-content: center;
}

.titlebarlayer .titlelayer .taglayer img {
  width: 6.3vw;
  height: 6.3vw;
  margin-top: 3vw;
}
.titlebarlayer .titlelayer .taglayer a {
  display: flex;
  justify-content: center;
  align-content: center;
}

.titlebarlayer .titlelayer .taglayer .tag {
  color: #fff;
  height: 45px;
  /* line-height: 45px; */
  font-size: 4.3vw;
  flex: 1;
}

.titlebarlayer .titlelayer .taglayer .img {
  color: #fff;
  padding-top: 3px;
  margin-left: 5px;
}

.titlebarlayer .titlelayer .taglayer .img img {
  width: 18px;
  height: 18px;
}
.backImg {
  display: flex;
  justify-content: center;
}
.backImg img {
  height: 74vw;
}
.vcodeLayer {
  display: flex;
  justify-content: center;
}

.vcodeLayer .contentlayer {
  display: flex;
  border-radius: 20px;
  width: 80%;
  height: 42vw;
  border: 1px solid;
  flex-wrap: wrap;
  flex-flow: column;
  align-items: center;
}

.vcodeLayer .contentlayer .title {
  font-size: 6vw;
  display: flex;
  justify-content: center;
  color: #039cf9;
  font-weight: 700;
}

.vcodeLayer .contentlayer input {
  height: 10vw;
  width: 80%;
  border-radius: 10vw;
  margin-top: 3vw;
  background-color: #ededf5;
  border: none;
}

.vcodeLayer .contentlayer .line {
  width: 85%;
  height: 2px;
  background-color: #a1a1a1;
  margin-top: 4vw;
}
.vcodeLayer .contentlayer .canvaslayer {
  margin-top: 5vw;
}

.downbtnlayer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10vw;
}

.downbtnlayer .btn {
  width: 70%;
  height: 12vw;
  background-color: #38aaff;
  align-items: center;
  color: #fff;
  border-radius: 10vw;
  font-size: 6vw;
  display: flex;
  justify-content: center;
}
</style>