<template>
  <div>
    <div class="header_layer wlimit">
      <div class="header_grade">
        <router-link :to="{ path: '/' }">
          <div class="grade_logo">
            <img src="../../../assets/img/pc/logo.png" />
          </div>
        </router-link>
        <div class="grade_items">
          <router-link
            :class="{ grade_select: 0 == grade_selectIndex }"
            :to="{ path: '/' }"
          >
            <div @click="selectgrade(0)">
              <p>首页</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${1}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 1 == grade_selectIndex }"
          >
            <div @click="selectgrade(1)">
              <p>幼小衔接</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${2}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 2 == grade_selectIndex }"
          >
            <div @click="selectgrade(2)">
              <p>一年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${3}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 3 == grade_selectIndex }"
          >
            <div @click="selectgrade(3)">
              <p>二年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${4}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 4 == grade_selectIndex }"
          >
            <div @click="selectgrade(4)">
              <p>三年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${5}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 5 == grade_selectIndex }"
          >
            <div @click="selectgrade(5)">
              <p>四年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${6}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 6 == grade_selectIndex }"
          >
            <div @click="selectgrade(6)">
              <p>五年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${7}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 7 == grade_selectIndex }"
          >
            <div @click="selectgrade(7)">
              <p>六年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${8}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 8 == grade_selectIndex }"
          >
            <div @click="selectgrade(8)">
              <p>七年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${9}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 9 == grade_selectIndex }"
          >
            <div @click="selectgrade(9)">
              <p>八年级</p>
            </div>
          </router-link>
          <router-link
            :to="`/searchpage/${10}/${seacher_selectIndex}/0`"
            :class="{ grade_select: 10 == grade_selectIndex }"
          >
            <div @click="selectgrade(10)">
              <p>九年级</p>
            </div>
          </router-link>
        </div>
        <div class="grade_search">
          <input type="text" placeholder="关键字搜索" v-model="seachtxt" />
          <div class="searchbtn" @click="selectkeyword">
            <a> 搜索</a>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="header_keywords wlimit">
      <div class="keywords">
        <div class="keywords_logo">
          <img src="../../../assets/img/pc/searchlogo.png" />
          <p>索引</p>
        </div>
        <div class="keywords_items">
          <router-link
            v-for="(p, index) in seacheritem"
            :to="`/searchpage/${grade_selectIndex}/${seacher_selectIndex}/0`"
          >
            <div
              :class="{ select: p.id == seacher_selectIndex }"
              @click="selectseacher(p.id, p.tag)"
            >
              <p>{{ p.tag }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetSearchList } from "@/utils/api";
export default {
  name: "MainPage",
  data() {
    return {
      grade_selectIndex: 0,
      seacher_selectIndex: 0,
      seacher_selecttag: "0",
      seachtxt: "",
      seacheritem: [
        { tag: "全部", id: 0 },
        { tag: "语文", id: 1 },
        { tag: "数学", id: 2 },
        { tag: "外语", id: 3 },
        { tag: "阅读", id: 4 },
        { tag: "知识", id: 5 },
        { tag: "课本", id: 6 },
        { tag: "习题", id: 7 },
        { tag: "口算", id: 8 },
        { tag: "晨读", id: 9 },
      ],
    };
  },
  methods: {
    selectgrade(index) {
      // console.log(index);
      this.grade_selectIndex = index;
    },
    selectseacher(index, tag) {
      this.seacher_selectIndex = index;
      this.seacher_selecttag = tag;
    },
    selectkeyword() {
      if (this.seachtxt != "") {
        this.$router.push({
          path: `/searchpage/${this.grade_selectIndex}/0/${this.seachtxt}`,
        });
      }
    },
    getUrlParam(gradeid, selectid, keyword) {
       console.log(gradeid, selectid);
      if (gradeid == undefined) {
        this.grade_selectIndex = 0;
        this.seacher_selectIndex = 0;
        this.seachtxt = "";
      } else {
        this.grade_selectIndex = gradeid;
        this.seacher_selectIndex = selectid;
        this.seachtxt = "";
        if (gradeid == 1) {
          
          this.seacheritem = [
            { tag: "全部", id: 0 },
            { tag: "拼音", id: 10 },
            { tag: "口算", id: 8 },
            { tag: "字帖", id: 11 },
          ];
        } else {
          this.seacheritem = [
            { tag: "全部", id: 0 },
            { tag: "语文", id: 1 },
            { tag: "数学", id: 2 },
            { tag: "外语", id: 3 },
            { tag: "阅读", id: 4 },
            { tag: "知识", id: 5 },
            { tag: "课本", id: 6 },
            { tag: "习题", id: 7 },
            { tag: "口算", id: 8 },
            { tag: "晨读", id: 9 },
          ];
        }
      }
    },
    GetSearch() {
      // console.log(this.seacheritem)
      GetSearchList().then((res) => {
        if (res.data.code == 200) {
          this.seacheritem = res.data.data;
        }
        // console.log(res.data);
      });
    },
  },
  created() {
    document.title = "中继站 资料详情介绍";
  },
  mounted() {
    // this.GetSearch();
    this.getUrlParam(
      this.$route.params.graderid,
      this.$route.params.searchid,
      this.$route.params.keyword
    );
  },
};
</script>

<style scoped>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #eff2f9;
  /* margin-top: 60px; */
}
a {
   cursor: pointer;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

a:-webkit-any-link {
    color: inherit;
}
.wlimit {
  margin: 0 auto;
  width: 90pc;
}
.header_layer {
  /* width: 100%; */
  height: 80px;
}

.header_layer .header_grade {
  display: flex;
}

.header_layer .header_grade .grade_logo {
  width: 110px;
  height: 85px;
  /* background-color: #49cbff; */
}

.header_layer .header_grade .grade_items {
  display: flex;
  margin-left: 100px;
}
.header_layer .header_grade .grade_items a {
  margin-top: 15px;
}
.header_layer .header_grade .grade_items a:hover {
  font-size: 16px;
}

.header_layer .header_grade .grade_items .grade_select {
  color: #fff;
}

.header_layer .header_grade .grade_items div {
  width: 90px;
  height: 70px;
  margin-top: -15px;
}

.header_layer .header_grade .grade_items .grade_select div {
  background-color: #49cbff;
  border-bottom: solid 6px #e94a47;
}

.header_layer .header_grade .grade_items p {
  line-height: 65px;
}

.header_layer .header_grade .grade_search {
  width: 300px;
  height: 85px;
  display: flex;
}

.header_layer .header_grade .grade_search input {
  width: 200px;
  height: 30px;
  border-radius: 16px;
  border: 2px solid#FF5500;
  margin-top: 30px;
  font-size: 14px;
  padding: 0px 15px 0px 15px;
  margin-left: 3px;
}

.header_layer .header_grade .grade_search .searchbtn {
  width: 55px;
  line-height: 45px;
  font-size: 14px;
  margin-top: 22px;
}

.header_layer .header_grade .grade_search .searchbtn a {
  height: 30px;
  border-radius: 16px;
  border: none;
  margin-top: 20px;
  font-size: 12px;
  padding: 0px 15px 0px 15px;
  color: #000;
}

.header_layer .header_grade .grade_search .searchbtn a:hover {
  height: 30px;
  border-radius: 16px;
  border: none;
  margin-top: 20px;
  font-size: 12px;
  padding: 0px 15px 0px 15px;
  color: #49cbff;
}
.header_keywords {
  height: 90px;
  overflow: hidden;
}

.header_keywords .keywords {
  display: flex;
}

.header_keywords .keywords .keywords_logo {
  width: 100px;
  height: 70px;
  display: flex;
  margin-left: 60px;
  margin-top: 10px;
}

.header_keywords .keywords .keywords_logo img {
  width: 30px;
  height: 27px;
  margin-top: 20px;
}

.header_keywords .keywords .keywords_logo p {
  line-height: 40px;
  margin-left: 10px;
  font-size: 14px;
  margin-top: 15px;
}

.header_keywords .keywords .keywords_items {
  /* display: flex; */
  margin-left: 50px;
  font-size: 14px;
  /* height: 84px; */
  width: 1046px;
}

.header_keywords .keywords .keywords_items div {
  line-height: 30px;
  border-radius: 20px;
  width: 100px;
  color: #000;
  margin-top: 30px;
}

.header_keywords .keywords .keywords_items a {
  float: left;
  display: block;
  height: 84px;
}

.header_keywords .keywords .keywords_items .select {
  background-color: #e94a47;
  color: #fff;
}

.header_keywords .keywords .gengduo {
  float: left;
  height: 84px;
  color: #636161;
  line-height: 90px;
  cursor: pointer;
}
</style>