<template>
  <div>
    <div class="downloadframe_layer" :style="{ display: isdwon }">
      <div class="frame_layer"></div>
      <div class="downloadbox_layer">
        <img src="../../assets/img/pc/shuziback.png" />
        <div class="txt_layer">
          <input type="text" v-model="codetxt" />
          <canvas
            id="canvas"
            :width="width"
            :height="height"
            @click="handleDraw"
          ></canvas>
        </div>

        <div class="codeerror" :style="{ display: iserror }"> 
            <p>验证码错误</p>
        </div>
        <div class="downloadbox_layer btn_layer" @click="down_url">
          <img src="../../assets/img/pc/downbtn.png" />
        </div>
        <a class="downbtn" @click="downbtn_hide">
          <img src="../../assets/img/pc/closebtn.png" />
        </a>
        <p style="margin-top:475px; z-index:100;color:white;font-size:14px;font-weight:600">注：本网站资料通过多种途径获取，资料内二维码与公众号谨慎关注</p>
      </div>
    </div>
    <div class="header_layer wlimit">
      <div class="header_grade">
        <router-link :to="{ path: '/' }">
          <div class="grade_logo">
            <img src="../../assets/img/pc/logo.png" />
          </div>
        </router-link>
        <div class="grade_items">
          <input type="text" placeholder="关键字搜索"  v-model="seachtxt"/>
        </div>
        <div class="grade_search"  @click="selectkeyword">
          <div class="searchbtn" style="font-size: 18px">
            <a> 搜索</a>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="detailed_layer detailed_wlimit">
      <div class="detailed">
        <div class="detailedtag">
          <p>资料详情</p>
        </div>
        <div class="detailedcontent">
          <div class="content_img">
            <img :src="data.img" />
          </div>
          <div class="content_info">
            <p class="content_info_tag">{{data.titlename}}</p>
            <p class="content_info_type">{{data.grade}}</p>
            <p class="content_info_txt">发布人：{{data.publisher}}</p>
            <p class="content_info_txt">发布时间：{{data.publishertime}}</p>
            <p class="content_info_txt">文件格式：{{data.filetype}}</p>
            <p class="content_info_txt">标签：</p>
            <p class="content_info_txt">{{data.labeltag}}</p>
          </div>
          <div class="content_down">
            <a @click="downbtn_show">
              <img src="../../assets/img/pc/downbtn.png" />
            </a>
          </div>
        </div>
        <div class="detailed_line"></div>
        <div class="detailed_desc">
          <div class="desc_img" v-for="p in data.imgs">
            <img :src="p"  style="width:100%"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { getDescdata } from "@/utils/api";  
import { GetDownUrl } from "@/utils/api";  
export default {
  name: "DetailPage",
  data() {
    return {
      isdwon: "none",
      iserror:"none",
      allTexts: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串
      width: 120,
      height: 40,
      imgCode: "",
      seachtxt: "",
      codetxt :"",
      id:"",
      data:{
        // img:"http://1.jmxiazai.com/test/images_2.png",
        // titlename:"一年级数学",
        // grade:"一年级",
        // publisher:"木林森",
        // publishertime:"2024-02-07",
        // filetype:'pdf',
        // labeltag:"一年级语文  一年级口算",
        // imgs:[
        //   "http://1.jmxiazai.com/test/images_2.png",
        //   "http://1.jmxiazai.com/test/images_2.png",
        //   "http://1.jmxiazai.com/test/images_2.png",
        //   "http://1.jmxiazai.com/test/images_2.png",
        // ]
      }
    };
  },
  methods: {
    down_url(){
      if(this.imgCode.toLowerCase() == this.codetxt.trim().toLowerCase()){
        
         this.iserror ="none"
        GetDownUrl({
          id:this.id
        }).then((res)=>{
          if(res.data.code == 200){
            this.downloadFile(res.data.downurl,res.data.filename);
          }
        })
        this.handleDraw();
      }
      else{
         this.iserror ="inline"
      }
    },
    getUrlParam(id){
        this.id=id;
       getDescdata({
        id: this.id,
      }).then((res) => {
        this.data = res.data.data;
        console.log(this.data);
      });
        
    },
     downloadImage(url) {
      var downtext = this.data.titlename;
      if(this.data.filetypeval == 2){
          downtext=downtext+".zip"
      }
      else if(this.data.filetypeval == 3){
          downtext=downtext+".pdf"
      }
      else if(this.data.filetypeval == 5){
          downtext=downtext+".doc"
      }
      this.downloadFile(url, downtext);
    },
    downloadFile(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        const blob = new Blob([xhr.response]);
        if ("download" in document.createElement("a")) {
          const link = document.createElement("a");
          link.download = fileName;
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        } else {
          navigator.msSaveBlob(blob, fileName);
        }
      };
      xhr.send();
    },
    selectkeyword() {
      if (this.seachtxt != "") {
        this.$router.push({
          path: `/searchpage/0/0/${this.seachtxt}`,
        });
      }
    },
    downbtn_show() {
      this.isdwon = "inline";
      this.imgCode = this.draw();
    },
    downbtn_hide() {
      this.isdwon = "none";
      this.iserror = "none";
      this.codetxt =""
    },
    //todo 点击图片刷新
    handleDraw() {
      this.imgCode = this.draw();
    },
    //todo 产生随机数
    randomNum(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    },
    //todo 产生随机颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max);
      const g = this.randomNum(min, max);
      const b = this.randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    },
    //todo 绘图
    draw() {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      //!! 1.填充背景颜色，背景颜色要浅一点
      ctx.fillStyle = this.randomColor(180, 230);
      // 填充的位置
      ctx.fillRect(0, 0, this.width, this.height);
      let imgCode = "";
      //!! 2.随机产生字符串，并且随机旋转
      for (let i = 0; i < 4; i++) {
        // 随机的四个字
        const text = this.allTexts[this.randomNum(0, this.allTexts.length)];
        imgCode += text;
        // 随机的字体大小
        const fontSize = this.randomNum(18, 40);
        // 字体随机的旋转角度
        const deg = this.randomNum(-30, 30);
        ctx.font = fontSize + "px Arial";
        ctx.textBaseline = "top";
        ctx.fillStyle = this.randomColor(80, 150);
        ctx.save();
        ctx.translate(30 * i + 15, 15);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(text, -15 + 5, -15);
        ctx.restore();
      }
      //!! 3.随机产生5条干扰线
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.lineTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.strokeStyle = this.randomColor(180, 230);
        ctx.closePath();
        ctx.stroke();
      }
      //!! 4.随机产生40个干扰的小点
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height),
          1,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fillStyle = this.randomColor(150, 200);
        ctx.fill();
      }
      return imgCode;
    },
   
  }, mounted(){
      console.log(123);
       this.getUrlParam(this.$route.params.id);
    },
    created(){
      document.title="中继站 资料详情介绍"
    }
};
</script>

<style scoped>

.wlimit {
  margin: 0 auto;
  width: 60pc;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.wlimit {
  margin: 0 auto;
  width: 60pc;
}
.header_layer {
  /* width: 100%; */
  height: 80px;
}
.codeerror{
margin-top:380px;z-index:100;margin-left:-250px;font-size:14px;color:red;position: absolute;
}
.header_layer .header_grade {
  display: flex;
}

.header_layer .header_grade .grade_logo {
  width: 110px;
  height: 85px;
}

.header_layer .header_grade .grade_items {
  margin-left: 100px;
  width: 700px;
}

.header_layer .header_grade .grade_items input {
  height: 30px;
  line-height: 5pc;
  margin-top: 20px;
  width: 600px;

  border: 2px solid #d9dce3;
  /* 设置边框 */
  border-radius: 16px;
  /* 圆角边框 */
  box-shadow: 0 1px 0 #ccc, /* 水平偏移 1px 的阴影，模拟深度 */ 0 1px 0 #999,
    /* 水平偏移 2px 的阴影，模拟更深度 */ 0 1px 0 #777,
    /* 水平偏移 3px 的阴影，模拟更深度 */ 0 1px 0 #666,
    /* 水平偏移 4px 的阴影，模拟更深度 */ 0 1px 0 #555,
    /* 水平偏移 5px 的阴影，模拟更深度 */ 0 1px 0 #444;
  /* 水平偏移 6px 的阴影，模拟更深度 */
  padding-left: 15px;
}

.header_layer .header_grade .grade_items input:focus {
  height: 30px;
  line-height: 5pc;
  margin-top: 20px;
  width: 600px;
  outline: none;
  border: 2px solid #d9dce3;
  /* 设置边框 */
}

.header_layer .header_grade .grade_search {
  width: 300px;
  height: 85px;
  display: flex;
}

.header_layer .header_grade .grade_search .searchbtn {
  width: 90px;
  height: 45px;
  font-size: 14px;
  margin-top: 25px;
}

.header_layer .header_grade .grade_search .searchbtn a {
  height: 30px;
  border-radius: 16px;
  border: none;
  margin-top: 20px;
  font-size: 16px;
  padding: 0px 15px 0px 15px;
  color: #000;
}

.header_layer .header_grade .grade_search .searchbtn a:hover {
  height: 30px;
  border-radius: 16px;
  border: none;
  margin-top: 20px;
  font-size: 16px;
  padding: 0px 15px 0px 15px;
  color: #49cbff;
}

.detailed_wlimit {
  margin: 0 auto;
  width: 65pc;
}

.detailed_layer .detailed {
  overflow: hidden;
  margin-top: 20px;
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 10px;
}
.detailed_layer .detailed .detailedtag {
  height: 40px;
  background-color: #efedee;
  text-align: left;
  margin-top: -20px;
}
.detailed_layer .detailed .detailedtag p {
  line-height: 42px;
  margin-left: 20px;
}
.detailed_layer .detailed .detailedcontent {
  height: 450px;
  display: flex;
  margin: 20px;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
}
.detailed_layer .detailed .detailedcontent .content_img {
  width: 400px;
  height: 380px;
  float: left;
}
.detailed_layer .detailed .detailedcontent .content_img img {
  height: 100%;
}
.detailed_layer .detailed .detailedcontent .content_info {
  width: 250px;
  height: 380px;
  text-align: left;
}
.detailed_layer .detailed .detailedcontent .content_info .content_info_tag {
  margin-top: 40px;
  font-size: 1.5em;
  font-weight: 800;
}
.detailed_layer .detailed .detailedcontent .content_info .content_info_type {
  font-size: 18px;
  color: #b60000;
}
.detailed_layer .detailed .detailedcontent .content_info .content_info_txt {
  font-size: 16px;
  color: #858e97;
}
.detailed_layer .detailed .detailedcontent .content_down {
  width: 250px;
  height: 480px;
}
.detailed_layer .detailed .detailedcontent .content_down img {
  width: 260px;
  margin-top: 70px;
  margin-left: 20px;
}
.downloadframe_layer {
  display: inline;
}

.downloadframe_layer .frame_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #555;
  opacity: 0.7;
  min-width: 1240px;
}

.downloadframe_layer .downloadbox_layer {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
}

.downloadframe_layer .downloadbox_layer img {
  margin-top: 230px;
  position: absolute;
}

.downloadframe_layer .txt_layer {
  position: absolute;
  width: 537px;
  height: 304px;
  margin-top: 350px;
  display: flex;
}

.downloadframe_layer .txt_layer input {
  height: 40px;
  width: 180px;
  margin-left: 100px;
  font-size: 22px;
  z-index:101
  /* margin-top: 5px; */
}

.downloadframe_layer .txt_layer canvas {
  width: 120px;
  height: 40px;
  /* background-color: #49cbff; */
  margin-left: 20px;
}

.downloadframe_layer .btn_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 200px;
}

.downloadframe_layer .btn_layer img {
  width: 320px;
}
.downloadbox_layer .downbtn img {
  margin-top: 260px;
  margin-left:410px;
  width: 35px;
}
.detailed_line {
  width: 95%;
  margin: 0px 0px 0px 20px;
  height: 2px;
  background-color: #efedee;
}
.detailed .detailed_desc {
  display: flex;
  margin-top: 40px;
}
.detailed .detailed_desc .desc_img {
  width: 25%;
  border-right: 1px solid #d9d9d9;
}
.detailed .detailed_desc .desc_img .img {
  width: 100%;
}
</style>