<template>
  <div>
    <div style="height: auto; min-width: 1440px">
      <img src="../../../assets/img/pc/back_1.png" style="width:100%;;max-width:1920px" />
    </div>
    <div class="category wlimit">
      <div class="category_layer">
        <router-link :to="`/searchpage/0/1/语文`">
          <div class="category_item">
            <img src="../../../assets/img/pc/yu.png" />

            <p style="margin-top: 60px">1~9年级语文资源共享</p>
            <p style="font-weight: 600">语文资料</p>
          </div>
        </router-link>
        <router-link :to="`/searchpage/0/2/数学`">
          <div class="category_item">
            <img src="../../../assets/img/pc/shu.png" />
            <p style="margin-top: 60px">1~9年级数学资源共享</p>
            <p style="font-weight: 600">数学资料</p>
          </div>
        </router-link>
         <router-link :to="`/searchpage/0/3/英语`">
          <div class="category_item">
            <img src="../../../assets/img/pc/wai.png" />
            <p style="margin-top: 60px">3~9年级外语资源共享</p>
            <p style="font-weight: 600">外语资料</p>
          </div>
        </router-link>
         <router-link :to="`/searchpage/0/4/阅读`">
          <div class="category_item">
            <img src="../../../assets/img/pc/yue.png" />
            <p style="margin-top: 60px">1~9年级阅读书籍共享</p>
            <p style="font-weight: 600">阅读笔记</p>
          </div>
         </router-link>
      </div>
    </div>
    <MainLead/>
  </div>
</template>

<script>
// import ItemList from "./ItemList.vue"
import MainLead from "./MainLead.vue"

export default {
  name: "HomePage",
  data() {
    return {};
  },
  components:{
    // ItemList,
    MainLead
  }
};
</script>

<style scoped>
a {
   cursor: pointer;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.wlimit {
  margin: 0 auto;
  width: 90pc;
}
.category {
  height: 400px;
  margin-top: 10px;
}

.category .category_layer {
  display: flex;
}

.category .category_layer .category_item {
  margin-right: 60px;
  width: 302px;
  height: 335px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category .category_layer .category_item img {
  width: 302px;
  height: 166px;
}

.category .category_layer .category_item p {
  font-size: 18px;
}
</style>