<template>
  <div  id="ccc">
    <MainPage />
    <ItemList />
  </div>
</template>

<script>
import ItemList from "./ItemList.vue";
import MainPage from "./MainPage.vue";
export default {
  name: "SearchPage",
  data() {
    return {};
  },
  components: {
    ItemList,
    MainPage
  },
};
</script>


<style scoped>
.category {
  height: 400px;
  margin-top: 10px;
}

#ccc {
  background-color: #eff2f9;
  /* margin-top: 60px; */
}
.category .category_layer {
  display: flex;
}

.category .category_layer .category_item {
  margin-right: 60px;
  width: 302px;
  height: 335px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category .category_layer .category_item img {
  width: 302px;
  height: 166px;
}

.category .category_layer .category_item p {
  font-size: 18px;
}
</style>