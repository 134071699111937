
import axios from "axios";
import qs from 'qs';

// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// axios.defaults.baseURL='/api'

export default {
   
    // get请求
    get(url, params = {}, headers = {}) {
        console.log(params)
        return new Promise((resolve, reject) => {
            axios.get(url, params, headers)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    post(url, params = {}) {
        // Vue.set(params,"osid",MyProperty.userid)
        // params.splice(1,"osid",MyProperty.userid)
        console.log(params)
        return new Promise((resolve, reject) => {
            // console.log(params);
            axios.post(url, params, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }
            )
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    postFile(url, params) {
        console.log(params)
        return new Promise((resolve, reject) => {
            axios.post(url,params, {
                headers: {
                    'Content-Type':'multipart/form-data'   
                }
            }
            )
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    postMapping(url, params = {}, headers = {}) {
        console.log(params)
        return new Promise((resolve, reject) => {
            axios.post(url, params, {
                headers: {
                    "Content-Type": "application/x-java;charset=utf-8"
                },

                paramsSerializer: params => {
                    return qs.stringify(params, { indices: false })
                }
            })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // delete请求
    delete(url, params = {}, headers = {}) {
        console.log(params)
        return new Promise((resolve, reject) => {
            axios.delete(url, params, headers)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // put请求
    put(url, params = {}, headers = {}) {
        console.log(params)
        return new Promise((resolve, reject) => {
            axios.put(url, params, headers)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}