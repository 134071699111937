<template>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <div>
    <div :class="classVal" @click="openMess">
      <img src="../../assets/img/pc/closebtn.png" @click="closeMess" />
      <p>留言反馈</p>
      <div>
        <textarea
          rows="7"
          cols="35"
          name="Messtext"
          placeholder="请输入您宝贵的意见,就是给我们最大的帮助!"
          v-model="Messtxt"
        ></textarea>
        <input type="button" value="提交" @click="updateMess" />
      </div>
    </div>
    <!-- <div style="width:100%;height:400px; position:absolute;display:none">
      <div style=" width:500px;height:300px;margin:auto;margin-top:100px;background-color:#efefef">
        <p style="border-left:20px">服务器升级提醒</p>
        <p style="width:450px;margin-left:20px"> 服务器将于2024年12点10分~2024年04点00分进行升级，请大家关注</p>
      </div>
    </div> -->
    <!-- <IndexPage/> -->
    <router-view :key="$route.fullPath"></router-view>
    <!-- <Item/> -->
    <!-- <MainIndex/> -->
  </div>
</template>

<script>
import { updateMess } from "@/utils/api";
// import Vue from 'vue';
import MainIndex from './module/MainIndex.vue'

// Vue.use(ElementUI);
export default {
  name: "App",
  data() {
    return {
      classVal: "MessArea_none",
      classvalonce: 0,
      Messtxt: "",
    };
  },
  components: {
    MainIndex,
  },
  methods: {
    openMess() {
      if (this.classVal == "MessArea_none" && this.classvalonce == 0) {
        this.classVal = "MessArea";
      }
      this.classvalonce = 0;
    },
    closeMess() {
      if (this.classVal == "MessArea") {
        this.classVal = "MessArea_none";
        this.classvalonce = 1;
      }
    },
    updateMess() {
      if (this.Messtxt.trim() == "") {
        console.log("不能为空");
      } else {
        updateMess({
          Messtxt: this.Messtxt,
        }).then((res) => {
          console.log("保存成功");

          this.classVal = "MessArea_none";
          this.classvalonce = 1;
        });
      }
      this.Messtxt = "";
    },
        //该方法用于判断是否进入手机端
    isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  },
  mounted(){
     //根据不同路由跳转不同页面
  //   if( this.isMobile() ){
  //     console.log("手机端")
  //     this.$router.push({
  //         path: `/Phone`,
  //       });
  //     // this.$router.replace('/Phone') // 手机路由
  //   }else{
  //     console.log("PC端")
  //      this.$router.push({
  //         path: `/`,
  //       });
  //     // this.$router.replace('/') // PC路由
  //   }
  console.log("Index.vue");
  }
};
</script>

<style scoped>
a{
   cursor: pointer;
   text-decoration: none;
   user-select: none;
   -moz-user-select: none;
}
.wlimit {
  margin: 0 auto;
  width: 60pc;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
body,
dd,
div,
dl,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
legend,
p,
pre,
span {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: PingFang SC, -apple-system, SF UI Text, Lucida Grande, STheiti,
    Microsoft YaHei, sans-serif;
}


body {
  display: block;
  background-color:#eff2f9;
  margin: 0px;
}
.MessArea_none {
  cursor: pointer;
  position: fixed;
  bottom: 100px;
  right: 100px;
  width: 100px;
  height: 100px;
  border-radius: 25px;
  border: 2px solid #0eb0c9;
  text-align: center;
  z-index: 100;
  background-color: white;
}
.MessArea_none p {
  margin-top: 35px;
  font-size: 18px;
}
.MessArea_none img {
  display: none;
}
.MessArea_none div {
  display: none;
}
.MessArea {
  position: fixed;
  bottom: 100px;
  right: 100px;
  width: 400px;
  height: 300px;
  border-radius: 25px;
  border: 2px solid #0eb0c9;
  z-index: 100;
  background-color: white;
}
.MessArea p {
  margin-top: 35px;
  font-size: 24px;
}
.MessArea img {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.MessArea div {
  display: block;
  width: 300px;
  margin-left: 50px;
}
.MessArea textarea {
  font-size: 16px;
  resize: none;
  border: 1px solid;
  margin-top: 10px;
}
.MessArea input {
  width: 120px;
  height: 35px;
  margin-top: 10px;
  font-size: 16px;
  margin-left: 180px;
  background-color: #0eb0c9;
  color: #efefef;
  border: 0;
  cursor: pointer;
}
</style>
