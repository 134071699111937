<template>
  <div class="content">
    <div class="titlebarlayer">
      <div class="titlelayer">
        <div class="taglayer">
            <img src="../../../assets/img/phone/returnImg.png"  @click="Click_backPage"/>
          <div class="tag">筛选</div>
        </div>
      </div>
    </div>
    <div class="oneclasslayer">
      <div class="titlelayer">
        <p>一年级资料分享</p>
      </div>
      <div class="line">
        <img src="../../../assets/img/phone/line.png" />
      </div>
      <div class="navigationlayer">
        <div class="navigationlistlayer">
          <div class="navigationlist">
           <router-link :to="`/searchpage/${1}/${0}/0`">
                <div class="typelayer">
                  <p>幼小衔接</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${2}/${0}/0`">
                <div class="typelayer">
                  <p>一年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${3}/${0}/0`">
                <div class="typelayer">
                  <p>二年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${4}/${0}/0`">
                <div class="typelayer">
                  <p>三年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${5}/${0}/0`">
                <div class="typelayer">
                  <p>四年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${6}/${0}/0`">
                <div class="typelayer">
                  <p>五年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${7}/${0}/0`">
                <div class="typelayer">
                  <p>六年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${8}/${0}/0`">
                <div class="typelayer">
                  <p>七年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${9}/${0}/0`">
                <div class="typelayer">
                  <p>八年级</p>
                </div>
              </router-link>
              <router-link :to="`/searchpage/${10}/${0}/0`">
                <div class="typelayer">
                  <p>九年级</p>
                </div>
              </router-link>
          </div>

          <div class="img">
            <img src="../../../assets/img/phone/search_1.png" />
          </div>
        </div>
      </div>
    </div>
     <!-- <div class="oneclasslayer">
      <div class="titlelayer">
        <p>一年级资料分享</p>
      </div>
      <div class="line">
        <img src="../../../assets/img/phone/line.png" />
      </div>
      <div class="navigationlayer">
        <div class="navigationlistlayer">
          <div class="navigationlist">
            <div class="typelayer">
              <p>试卷</p>
            </div>
            <div class="typelayer">
              <p>试卷</p>
            </div>
            <div class="typelayer">
              <p>试卷</p>
            </div>
            <div class="typelayer">
              <p>试卷</p>
            </div>
            <div class="typelayer">
              <p>试卷</p>
            </div>
            <div class="typelayer">
              <p>试卷</p>
            </div>
            <div class="typelayer">
              <p>试卷</p>
            </div>
            <div class="typelayer">
              <p>试卷</p>
            </div>
          </div>

          <div class="img">
            <img src="../../../assets/img/phone/search_2.png" />
          </div>
        </div>
      </div>
    </div> -->
     <div class="downlayer">
      <div class="txt">
        <p>提   交</p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {};
  },
  mounted() {
    document.body.style.backgroundColor = "#90dafd";
  },
  methods: {
    Click_backPage() {
      this.$router.back(-1);
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
html {
  height: 100vw;
  width: 100vw;
}
body {
  background-color: #90dafd;
  margin: 0;
  font-size: 0.13rem;
  display: flex;
  flex-direction: column;
  background-color: #90dafd;
}
.content {
  background-color: #90dafd;
}
#app {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  background-color: #90dafd;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.content {
  height: 100%;
  background-color: #90dafd;
  margin-bottom: 20px;
}

input {
  border: none;
  outline: none;
}

p {
  margin: 0px;
}
.titlebarlayer {
  height: 12vw;
  background-color: #90dafd;
}

.titlebarlayer .titlelayer {
  background-color: #4bcdff;
  width: 100%;
  height: 100%;
}

.titlebarlayer .titlelayer .taglayer {
  padding: 0px 20px 0px 20px;
  display: flex;
  line-height: 12vw;
  justify-content: center;
  align-content: center;
}
.titlebarlayer .titlelayer .taglayer img {
  width: 6.3vw;
  height: 6.3vw;
  margin-top: 3vw;
}
.titlebarlayer .titlelayer .taglayer a {
  display: flex;
  justify-content: center;
  align-content: center;
}

.titlebarlayer .titlelayer .taglayer .tag {
  color: #fff;
  height: 45px;
  /* line-height: 45px; */
  font-size: 4.3vw;
  flex: 1;
}

.titlebarlayer .titlelayer .taglayer .img {
  color: #fff;
  padding-top: 3px;
  margin-left: 5px;
}

.titlebarlayer .titlelayer .taglayer .img img {
  width: 18px;
  height: 18px;
}
.oneclasslayer .titlelayer {
  color: #fff;
  padding-left: 30px;
  display: flex;

  margin-top: 3vw;
}

.navigationlayer {
  background-color: #ffff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
}

.navigationlayer .navigationlistlayer {
  background-color: #e0ebff;
  display: flex;
}

.navigationlayer .navigationlistlayer .navigationlist {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 5px 5px;
}

.navigationlayer .navigationlistlayer .navigationlist .typelayer {
  width: 16.33vw;
  height: 9vw;
  background-color: #d6e4fe;
  border-radius: 20px;
  color: #1e62eb;
  font-size: 3.3vw;
  line-height: 9vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 3.33vw 0px 3.33vw 1.33vw;
  z-index: 10;
}

.navigationlayer .navigationlistlayer .img {
  width: 100px;
  justify-content: center;
  text-align: center;
}

.navigationlayer .navigationlistlayer .img img {
  width: 28.33vw;
  margin-left: -15vw;
}

.line img {
  width: 100%;
}

.downlayer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 10vw;
}
.downlayer .txt {
  background-color: #f67148;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 70%;
  height: 9vw;
  align-items: center;
  border-radius: 20px;
}
</style>