<template>
  <div>
    <div class="category wlimit">
      <div class="category_layer">
        <div class="all_layer">
          <div class="banji_layer">
            <div :class="card">
              <div class="xiao_layer" @mousemove="xiao_move">
                <div class="xiao_left">
                  <div class="xiao_title">
                    <p>小学资料</p>
                  </div>
                  <div class="xiao_tag">
                    <p>优质资料免费下载</p>
                  </div>
                  <div class="class_layer">
                    <router-link :to="`/searchpage/${2}/${0}/0`">
                      <div class="content_layer">
                        <p>一年级</p>
                      </div>
                    </router-link>
                    <router-link :to="`/searchpage/${3}/${0}/0`">
                      <div class="content_layer">
                        <p>二年级</p>
                      </div>
                    </router-link>
                    <router-link :to="`/searchpage/${4}/${0}/0`">
                      <div class="content_layer">
                        <p>三年级</p>
                      </div>
                    </router-link>
                    <router-link :to="`/searchpage/${5}/${0}/0`">
                      <div class="content_layer">
                        <p>四年级</p>
                      </div>
                    </router-link>
                    <router-link :to="`/searchpage/${6}/${0}/0`">
                      <div class="content_layer">
                        <p>五年级</p>
                      </div>
                    </router-link>
                    <router-link :to="`/searchpage/${7}/${0}/0`">
                      <div class="content_layer">
                        <p>六年级</p>
                      </div>
                    </router-link>
                  </div>
                  <div class="xiao_img">
                    <img src="../../../assets/img/pc/xiaoxuelogo.png" />
                  </div>
                </div>
                <div class="xiao_right">
                  <img src="../../../assets/img/pc/xiaoxuelogo.png" />
                </div>
              </div>

              <div class="chu_layer" @mousemove="chu_move">
                <div class="chu_left">
                  <div class="chu_title">
                    <p>初中资料</p>
                  </div>
                  <div class="chu_tag">
                    <p>优质资料免费下载</p>
                  </div>
                  <div class="class_layer">
                    <router-link :to="`/searchpage/${8}/${0}/0`">
                      <div class="content_layer">
                        <p>七年级</p>
                      </div>
                    </router-link>
                    <router-link :to="`/searchpage/${9}/${0}/0`">
                      <div class="content_layer">
                        <p>八年级</p>
                      </div>
                    </router-link>
                    <router-link :to="`/searchpage/${10}/${0}/0`">
                      <div class="content_layer">
                        <p>九年级</p>
                      </div>
                    </router-link>
                  </div>
                  <div class="chu_img">
                    <img src="../../../assets/img/pc/chuzhonglogo.png" />
                  </div>
                </div>
                <div class="chu_right">
                  <img src="../../../assets/img/pc/chuzhonglogo.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="title_layer">
            <div class="img_layer">
              <router-link :to="`/searchpage/${0}/${0}/试卷`">
                <div class="content_layer1">
                  <img src="../../../assets/img/pc/zu_1.png" />
                </div>
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/阅读`">
                <div class="content_layer1">
                  <img src="../../../assets/img/pc/zu_2.png" />
                </div>
              </router-link>
              <router-link :to="`/searchpage/${0}/${0}/口算`">
                <div class="content_layer1">
                  <img src="../../../assets/img/pc/zu_3.png" />
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box_layer wlimit">
      <div class="box class_one">
        <div class="title">
          <p>一年级资料分享</p>
        </div>
        <div class="content_layer">
          <router-link :to="`/searchpage/${2}/${0}/语文`">
            <div>
              <img src="../../../assets/img/pc/one_yu.png" />
            </div>
          </router-link>
          <router-link :to="`/searchpage/${2}/${0}/数学`">
            <div>
              <img src="../../../assets/img/pc/one_kou.png" />
            </div>
          </router-link>
          <router-link :to="`/searchpage/${2}/${0}/阅读`">
            <div>
              <img src="../../../assets/img/pc/one_du.png" />
            </div>
          </router-link>
          <router-link :to="`/searchpage/${2}/${0}/口算`">
            <div>
              <img src="../../../assets/img/pc/one_shu.png" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="box_layer wlimit">
      <div class="box class_one">
        <div class="title">
          <p>二年级资料分享</p>
        </div>
        <div class="content_layer">
          <router-link :to="`/searchpage/${3}/${0}/卷`">
            <div>
              <img src="../../../assets/img/pc/two_ce.png" />
            </div>
          </router-link>
          <router-link :to="`/searchpage/${3}/${0}/口算`">
            <div>
              <img src="../../../assets/img/pc/two_shu.png" />
            </div>
          </router-link>
          <router-link :to="`/searchpage/${3}/${0}/知识`">
            <div>
              <img src="../../../assets/img/pc/two_zhi.png" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="box_layer wlimit">
      <div class="box class_one">
        <div class="title">
          <p>三年级资料分享</p>
        </div>
        <div class="content_layer">
          <router-link :to="`/searchpage/${4}/${0}/语文`">
            <div>
              <img src="../../../assets/img/pc/three_yu.png" />
            </div>
          </router-link>
          <router-link :to="`/searchpage/${4}/${0}/数学`">
            <div>
              <img src="../../../assets/img/pc/three_shu.png" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainLead",
  data() {
    return {
      card: "nei_layer",
    };
  },
  methods: {
    xiao_move() {
      this.card = "nei_layer";
    },
    chu_move() {
      this.card = "nei_layer_smail";
    },
  },
};
</script>

<style scoped>
a {
   cursor: pointer;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.wlimit {
  margin: 0 auto;
  width: 90pc;
}
.all_layer {
  display: flex;
  width: 100%;
}
.wlimit {
  margin: 0 auto;
  width: 90pc;
}

.all_layer .banji_layer {
  width: 1030px;
  height: 300px;
  display: flex;
}

.all_layer .banji_layer .nei_layer {
  padding: 20px 20px 20px 20px;
  background-color: #fff;
  display: flex;
}

.all_layer .banji_layer .nei_layer .xiao_layer {
  background: linear-gradient(to right, #f4f7ff, #dfeaff);
  width: 625px;
  display: flex;
  min-width: 650px;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left {
  height: 100%;
  padding-left: 35px;
  padding-top: 20px;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left .xiao_title {
  height: 50px;
  display: flex;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left .xiao_title p {
  color: #1f61eb;
  font-size: 24px;
  font-family: SimHei;
  font-weight: 650;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left .xiao_tag {
  height: 55px;
  display: flex;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left .xiao_tag p {
  color: #1f61eb;
  font-size: 18px;
  font-family: SimHei;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left .class_layer {
  height: 45px;
  display: flex;
  flex-wrap: wrap;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left .xiao_img {
  display: none;
  float: right;
  padding: 20px;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_left .xiao_img img {
  width: 100px;
  height: 100px;
}

.all_layer
  .banji_layer
  .nei_layer
  .xiao_layer
  .xiao_left
  .class_layer
  .content_layer {
  color: #1f61eb;
  font-size: 16px;
  font-family: SimHei;
  width: 100px;
  background-color: #d7e4fe;
  border-radius: 20px;
  margin: 0px 20px 20px 0px;
  height: 35px;
  line-height: 35px;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_right {
  min-width: 250px;
  height: 100%;
  padding: 10px 0px 10px 0px;
}

.all_layer .banji_layer .nei_layer .xiao_layer .xiao_right img {
  width: 200px;
  height: 200px;
}

.all_layer .banji_layer .nei_layer .chu_layer {
  background: linear-gradient(to right, #f3fbf0, #ecfce2);
  width: 325px;
  margin-left: 20px;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left {
  height: 100%;
  padding-left: 35px;
  padding-top: 20px;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left .chu_title {
  height: 50px;
  display: flex;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left .chu_title p {
  color: #73d13d;
  font-size: 24px;
  font-family: SimHei;
  font-weight: 650;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left .chu_tag {
  height: 55px;
  display: flex;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left .chu_tag p {
  color: #73d13d;
  font-size: 18px;
  font-family: SimHei;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left .chu_img {
  display: flex;
  float: right;
  padding: 20px;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left .chu_img img {
  width: 100px;
  height: 100px;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_left .class_layer {
  height: 45px;
  display: none;
  flex-wrap: wrap;
}

.all_layer .banji_layer .nei_layer .chu_layer .chu_right {
  min-width: 250px;
  height: 100%;
  padding: 10px 0px 10px 0px;
  display: none;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_right img {
  width: 200px;
  height: 200px;
}

.all_layer .title_layer {
  width: 330px;
  background-color: #fff;
  padding-left: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  height: 300px;
}

.all_layer .title_layer .content_layer1 {
  /* width: 280px; */
  height: 60px;
  /* background-color: #9ae17b; */
  /* border-radius: 40px; */
  /* line-height: 60px; */
}
.all_layer .title_layer .img_layer {
  height: 250px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
.all_layer .title_layer .content_layer1 img {
  height: 55px;
}

.all_layer .title_layer .content_layer2 {
  width: 280px;
  height: 60px;
  background-color: #859cfa;
  border-radius: 40px;
  line-height: 60px;
}

.all_layer .title_layer .content_layer3 {
  width: 280px;
  height: 60px;
  background-color: #5ca6fd;
  border-radius: 40px;
  line-height: 60px;
}

.all_layer {
  display: flex;
  width: 100%;
}

.all_layer .banji_layer {
  width: 1030px;
  height: 300px;
  display: flex;
}

.all_layer .banji_layer .nei_layer_smail {
  padding: 20px 20px 20px 20px;
  background-color: #fff;
  display: flex;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer {
  background: linear-gradient(to right, #f4f7ff, #dfeafe);
  width: 325px;
  display: flex;
  min-width: 325px;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left {
  height: 100%;
  padding-left: 35px;
  width: 325px;
  padding-top: 20px;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left .xiao_title {
  height: 50px;
  display: flex;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left .xiao_title p {
  color: #1f61eb;
  font-size: 24px;
  font-family: SimHei;
  font-weight: 650;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left .xiao_tag {
  height: 55px;
  display: flex;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left .xiao_tag p {
  color: #1f61eb;
  font-size: 18px;
  font-family: SimHei;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left .class_layer {
  height: 45px;
  display: flex;
  flex-wrap: wrap;
  display: none;
}

.all_layer
  .banji_layer
  .nei_layer_smail
  .xiao_layer
  .xiao_left
  .class_layer
  .content_layer {
  color: #1f61eb;
  font-size: 16px;
  font-family: SimHei;
  width: 100px;
  background-color: #d6e4fe;
  border-radius: 20px;
  margin: 0px 20px 20px 0px;
  height: 35px;
  line-height: 35px;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left .xiao_img {
  display: flex;
  float: right;
  padding: 20px;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_left .xiao_img img {
  width: 100px;
  height: 100px;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_right {
  min-width: 0px;
  height: 100%;
  padding: 10px 0px 10px 0px;
  display: none;
}

.all_layer .banji_layer .nei_layer_smail .xiao_layer .xiao_right img {
  width: 0px;
  height: 0px;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer {
  background: linear-gradient(to right, #f3fbf0, #ecfce2);
  width: 625px;
  margin-left: 20px;
  display: flex;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left {
  height: 100%;
  padding-left: 35px;
  padding-top: 20px;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left .chu_title {
  height: 50px;
  display: flex;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left .chu_title p {
  color: #73d13d;
  font-size: 24px;
  font-family: SimHei;
  font-weight: 650;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left .chu_tag {
  height: 55px;
  display: flex;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left .chu_tag p {
  color: #73d13d;
  font-size: 18px;
  font-family: SimHei;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left .class_layer {
  height: 45px;
  display: flex;
  flex-wrap: wrap;
}

.all_layer
  .banji_layer
  .nei_layer_smail
  .chu_layer
  .chu_left
  .class_layer
  .content_layer {
  color: #59b027;
  font-size: 16px;
  font-family: SimHei;
  width: 100px;
  background-color: #b1e683;
  border-radius: 20px;
  margin: 0px 20px 20px 0px;
  height: 35px;
  line-height: 35px;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left .chu_img {
  display: none;
  float: right;
  padding: 20px;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_left .chu_img img {
  width: 100px;
  height: 100px;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_right {
  min-width: 250px;
  height: 100%;
  padding: 10px 0px 10px 0px;
}

.all_layer .banji_layer .nei_layer_smail .chu_layer .chu_right img {
  width: 200px;
  height: 200px;
}

.all_layer .title_layer {
  width: 330px;
  background-color: #fff;
  padding-left: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  height: 280px;
  padding-top: 20px;
}

.all_layer .title_layer .content_layer1 {
  /* width: 280px; */
  height: 60px;
  /* background-color: #9ae17b; */
  /* border-radius: 40px; */
  /* line-height: 60px; */
}

.all_layer .title_layer .content_layer2 {
  width: 280px;
  height: 60px;
  background-color: #859cfa;
  border-radius: 40px;
  line-height: 60px;
}

.all_layer .title_layer .content_layer3 {
  width: 280px;
  height: 60px;
  background-color: #5ca6fd;
  border-radius: 40px;
  line-height: 60px;
}

.all_layer .title_layer .content_layer2,
.content_layer1,
.content_layer3 p {
  color: #fff;
  font-size: 24px;
}
.class_one .title {
  display: flex;
  padding-left: 35px;
  color: #525252;
  font-size: 22px;
  margin: 30px 0px 20px 0px;
}

.class_one .content_layer {
  /* height: 300px; */
  background-color: #fff;
  padding: 30px 30px 30px 30px;
  display: flex;
  justify-content: space-between;
}
.class_one .content_layer img {
  height: 220px;
}
</style>